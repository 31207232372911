import * as __au2ViewDef from './au-gallery.html';
import { customElement } from '@aurelia/runtime-html';
import GLightbox from 'glightbox';
import {inject} from "aurelia";

import 'glightbox/dist/css/glightbox.min.css'

@customElement(__au2ViewDef)
@inject()
export class AuGallery {

    gallery: any;

    constructor(private element: Element) {}

    attached() {
        const images = this.element.getElementsByTagName("img");
        const sources = []
        let index = 0;
        Array.from(images).forEach((image) => {
            sources.push({
                'href': image.src,
                'type': 'image',
                /*'description': image.alt,*/
            })
            image['gallery_index'] = index;
            image.parentElement.addEventListener('click', (e) => {
                this.openGallery(e);
            })
            index++;
        })

        this.gallery = GLightbox({
            elements: sources
        });

    }

    openGallery(e) {
        this.gallery.openAt(e.target['gallery_index']);
    }
}
