import Aurelia from 'aurelia';
import { App } from './app';
import { DialogDefaultConfiguration } from '@aurelia/runtime-html';

import 'whatwg-fetch'

import {Swiper} from "./components/swiper/swiper";
import {AuGallery} from "./components/au-gallery/au-gallery";

const au = new Aurelia();
au.register(Swiper)
au.register(AuGallery)
au.register(DialogDefaultConfiguration.customize(settings => {
    settings.startingZIndex = 500;
}))
au.enhance({
    host: document.querySelector('body'),
    component: App,
})
