import * as __au2ViewDef from './swiper.html';
import { customElement } from '@aurelia/runtime-html';
import {bindable, inject} from "aurelia";
import { Navigation, Pagination } from 'swiper';
import * as SwiperJs from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


SwiperJs.Swiper.use([Navigation, Pagination])

@customElement(__au2ViewDef)
@inject()
export class Swiper {
    @bindable speed = 300;
    @bindable easing = 'ease-out';
    @bindable initialSlide = 0;
    @bindable slidesPerView = 1;
    @bindable spaceBetween = 0;
    @bindable loop = true;
    @bindable breakpoints: any = '';
    @bindable autoplay: any | boolean = false;
    @bindable delay = 3000;
    @bindable preloadImages = true;
    @bindable lazy = false;
    @bindable title = '';

    instance = '';
    swiper: any;
    swiperElement: HTMLElement;

    constructor(private element: Element) {}

    attached() {
        if(this.autoplay) {
            this.autoplay = {
                delay: this.delay
            }
        }

        this.swiper = new SwiperJs.Swiper(this.swiperElement as HTMLElement, {
            direction: 'horizontal',
            speed: this.speed,
            initialSlide: Number(this.initialSlide),
            slidesPerView: Number(this.slidesPerView),
            spaceBetween: Number(this.spaceBetween),
            loop: Boolean(this.loop),
            centeredSlides: true,
            preloadImages: this.preloadImages,
            lazy: this.lazy,
            autoplay: this.autoplay,
            breakpoints: this.breakpoints,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
            },
        });

    }

}
